@import '~swiper/css/swiper.css';

.slider {
  .swiper-pagination-bullets {
    bottom: 4rem;
    .swiper-pagination-bullet {
      width: 0.8rem;
      height: 0.8rem;
      margin: 0 1rem !important;

      opacity: 1;
      background-color: $white;
      position: relative;
      transition: background $trans;

      &::after {
        content: '';
        width: 2rem;
        height: 2rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
  }

  &--blue {
    @include slider-color($secondary-blue);
  }

  &--gold {
    @include slider-color($secondary-gold);
  }

  &--red {
    @include slider-color($secondary-red);
  }

  &--green {
    @include slider-color($secondary-green-1);
  }

  &--black {
    @include slider-color($black);
  }

  &--purple {
    @include slider-color($secondary-purple);
  }
}
