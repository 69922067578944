.list {
  &--lang {
    width: 100%;
    background-color: $white;

    .list__item {
      width: 100%;
    }

    a {
      display: inline-block;
      width: 100%;
      padding: 0.725rem 1.5rem;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.2;
      color: $black;
      opacity: 0.5;
      transition: opacity $trans;

      @include desktop {
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &--menu {
    display: flex;

    @include mobile {
      flex-direction: column;
    }
    & > .list__item {
      &:not(:last-of-type) {
        margin-right: 3rem;

        @include mobile {
          margin: 0 0 2rem 0;
        }
      }
    }
  }

  &--menu-inner {
    & > .list__item {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }
}
