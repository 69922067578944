.search {
  &__head {
    margin-bottom: 4rem;
  }

  &__body {
  }

  &__match {
    background-color: rgba($secondary-blue, 0.4);
  }

  &__foot {
    margin-top: 8rem;

    @include mobile {
      margin-top: 6rem;
    }
  }

  &__results-heading {
    margin-bottom: 1rem;

    @include mobile {
      margin-bottom: 0;
    }

    &:not(:first-of-type) {
      margin-top: 6rem;

      @include mobile {
        margin-bottom: 0;
        margin-top: 4rem;
      }
    }
  }
}
