.modal {
  display: block;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  transition: visibility 600ms linear;
  overflow-x: hidden;
  overflow-y: auto;
  webkit-overflow-scrolling: touch;
  will-change: visibility, opacity;

  &__overlay {
    position: fixed;
    top: 0;
    // left: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: #140e19;
    z-index: -1;
    transition: opacity 450ms ease-in-out;
    will-change: opacity;
  }

  &__wrap {
    width: 100%;
    min-height: 100%;
    background-color: $white;
    position: relative;
    left: 100%;
    transition: transform 300ms ease-in-out;
    will-change: transform, opacity;
  }
  &__body {
    width: 100%;
    position: relative;
  }

  &__close {
    display: inline-block;
    font-size: 0;
    position: relative;
    right: 3rem;
    top: 3rem;
    position: absolute;
    z-index: 2;
    user-select: none;
    cursor: pointer;
    transition: transform $trans;

    // @include mobile {
    //   right: 2rem;
    //   top: 2rem;
    //   position: fixed;

    //   &::before {
    //     content: '';
    //     display: block;
    //     width: 6rem;
    //     height: 6rem;
    //     position: absolute;
    //     transform: translate(-1.5rem, -1.5rem);
    //   }
    // }

    svg {
      width: 3.6rem;
      height: 3.6rem;
      stroke: $black;

      // @include mobile {
      //   width: 3rem;
      //   height: 3rem;
      // }
    }

    &:hover {
      @include desktop {
        transform: scale(1.15);
      }
    }
  }

  &--halfScreen {
    .modal__wrap {
      width: 50%;
      min-width: 66rem;

      @include mobile {
        width: 100%;
        min-width: unset;
      }
    }

    .modal__body {
      padding: 12.5rem 0;

      @include mobile {
        padding: 7.8rem 0 4rem 0;
      }

      .heading--l {
        + .form {
          margin-top: 6rem;

          @include mobile {
            margin-top: 4rem;
          }
        }
      }
    }
    .row {
      max-width: 62rem;
      margin-left: unset;
    }
  }

  &--fromTop {
    .modal__wrap {
      width: 100%;
      left: 0;
      bottom: 100%;
      background-color: transparent;
      pointer-events: none;
    }
    .modal__body {
      background-color: $white;
      pointer-events: all;
    }

    .modal__close {
      pointer-events: all;
    }
  }

  &--fadeIn {
    .modal__wrap {
      transition: opacity 300ms ease-in-out !important;
    }

    .modal__overlay {
      display: none;
    }

    .modal__body {
      padding: 8rem 0 3rem;
    }
  }

  &--accessibility {
    .modal__body {
      padding: 2.7rem 0;

      @include mobile {
        padding: 9rem 0 6rem 0;
      }
    }
  }

  &--search {
    .modal__body {
      padding: 9rem 0 6rem 0;
    }

    .form {
      max-width: 89rem;
    }

    .form__control--search {
      max-width: unset;
    }

    .tag__container {
      margin-top: 4rem;
    }
  }

  &--show {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;

    .modal__wrap {
      transition: transform 600ms ease-in-out;
      transform: translateX(-100%);
      opacity: 1;
    }

    .modal__overlay {
      opacity: 0.4;
      left: 0;
    }
  }

  &--show.modal--fromTop {
    .modal__wrap {
      transform: translate(0, 100%);
    }
  }
}
