.dropdown {
  position: relative;

  &__label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    position: relative;

    svg [data-name='vertical'] {
      transition: transform 400ms ease-in-out;
      transform-origin: center;
    }
  }

  &__button-back {
    font-size: 1.5rem;
    color: $white;
    font-weight: bold;
    line-height: 2.4rem;
    margin-bottom: 2rem;
    user-select: none;
    cursor: pointer;

    svg {
      width: 2.4rem;
      height: 1.7rem;
      pointer-events: none;
      fill: $white;
      margin-right: 1rem;
      margin-bottom: -0.2rem;
    }
  }

  &__arrow {
    display: inline-block;
    font-size: 0;
    transition: transform $trans;
    margin-left: 1rem;

    svg {
      width: 1.8rem;
      height: 0.9rem;
      stroke: $black;
    }
  }

  &__content {
    background-color: $white;
    margin-top: -1px;
    backface-visibility: hidden;
  }

  &--hasArrow-up {
    .dropdown__content {
      margin-top: 2.5rem;
      &:before {
        content: '';
        position: absolute;
        top: 1px;
        left: 50%;
        transform: translate3d(-50%, -100%, 0);
        width: 0;
        height: 0;
        border-left: 1.5rem solid transparent;
        border-right: 1.5rem solid transparent;
        border-bottom: 1.5rem solid $white;
      }

      &::after {
        content: '';
        position: absolute;
        width: 50%;
        left: 50%;
        transform: translate3d(-50%, -100%, 0);
        top: 1px;
        height: 3rem;
      }
    }
  }

  &--clickable {
    .dropdown {
      &__content {
        display: none;

        &[data-slidetoggle='false'] {
          overflow: unset !important;
          height: auto !important;
        }
      }
    }
  }

  &--hoverable {
    .dropdown {
      &__content {
        display: block;
        position: absolute;

        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transition: opacity 200ms ease-out;
        z-index: 2;
      }
    }

    @include desktop {
      &:hover {
        .dropdown__arrow {
          transform: rotate(180deg);
        }
        .dropdown__content {
          opacity: 1;
          pointer-events: auto;
          visibility: visible;
        }
      }
    }
  }

  &--fadeIn {
    .dropdown__content {
      display: block;
      transition: opacity $trans, visibility $trans;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    &.fade-in {
      .dropdown__content {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }

  &[data-dropdown-state='open'] {
    .dropdown__label,
    .expandable__state-icon {
      .button__text {
        &:first-of-type {
          display: none !important;
        }
      }

      svg [data-name='vertical'] {
        transform: rotate(90deg);
      }
    }
  }

  &[data-dropdown-state='closed'] {
    .dropdown__label,
    .expandable__state-icon {
      .button__text {
        &:last-of-type {
          display: none !important;
        }
      }
    }
  }

  &[data-dropdown-state='open'] {
    .dropdown__arrow {
      transform: rotate(180deg);
    }
  }

  &[data-dropdown-state='closed'] {
    .dropdown__arrow {
      transform: rotate(0);
    }
  }
}
