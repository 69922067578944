.pagination {
  display: flex;
  justify-content: center;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__arrow {
    display: inline-flex;
    align-items: center;
    padding: 0.4rem;
    svg {
      width: 2.4rem;
      height: 1.7rem;
      fill: $black;
      transition: transform $trans;
      pointer-events: none;
    }

    span {
      font-size: 1.7rem;
      font-weight: bold;
    }

    &:hover {
      svg {
        transform: scaleX(1.15);
      }
    }

    &--prev {
      margin-right: 2.2rem;
      svg {
        transform-origin: right center;
      }

      span {
        display: inline-block;
        margin-left: 1rem;
      }
    }

    &--next {
      margin-left: 2.2rem;

      svg {
        transform-origin: left center;
      }

      span {
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }

  &__arrow-with-text {
    display: flex;
    flex-direction: column;
    width: 25rem;

    @include mobile {
      width: calc(50% - 0.5rem);
    }

    .pagination__arrow:hover ~ .pagination__post-title {
      opacity: 1;
    }

    &--prev {
      .pagination__arrow {
        margin-right: auto;
      }
    }
    &--next {
      text-align: right;
      .pagination__arrow {
        align-self: flex-end;
      }
    }
  }

  &__post-title {
    display: inline-block;
    margin-top: 2rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: calc(23 / 15);
    opacity: 0.5;
    transition: opacity $trans;

    @include mobile {
      margin-top: 1.5rem;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__page {
    @extend .link, .link--l, .link--black, .link--hasUnderline;
    padding: 0 0.4rem;
    &:not(.active) {
      color: rgba($black, 0.5);
      transition: color $trans;

      &:hover {
        color: $black;
        background-size: 0;
      }
    }

    + .pagination__page {
      margin-left: 2.2rem;
    }

    &.active {
      transition: none;
      background-size: 100% 2px;
    }
  }

  &--type-2 {
    .pagination__inner {
      width: 100%;
      justify-content: space-between;
    }

    .button--primary {
      @include mobile {
        margin: 0 0 0 auto;
      }
    }
  }
}
