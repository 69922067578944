.popup {
  position: fixed;
  z-index: 9999;
  &__body {
  }

  &__close {
    user-select: none;
    cursor: pointer;
    svg {
      pointer-events: none;
      width: 2rem;
      height: 2rem;
      fill: $black;
      transition: fill$trans;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 4rem;
      height: 4rem;
      transform: translate(-25%, -25%);
    }

    @include desktop {
      &:hover {
        svg {
          fill: $secondary-blue;
        }
      }
    }
  }

  &--policy {
    max-width: 48rem;
    width: 100%;
    padding: 2rem 3rem;
    background-color: $white;
    bottom: 2rem;
    right: 2rem;
    border-radius: $border-radius;
    transition: opacity 450ms ease-in-out;
    box-shadow: $shadow;

    @include mobile {
      max-width: unset;
      width: calc(100% - 2rem);
      padding: 1.5rem;
      bottom: 1rem;
      right: 1rem;
    }

    .text-block {
      max-width: 38rem;

      @include mobile {
        width: 100%;
        padding-right: 1.3rem;
      }
    }

    // @include mobile {
    //   width: 100%;
    //   left: 0;
    //   bottom: 0;
    //   border-left: 0;
    //   border-right: 0;
    //   border-radius: 0;
    //   max-width: unset;
    // }

    .popup__body {
      display: flex;
      justify-content: space-between;
    }
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
