.table {
  display: block;
  border-bottom: 0;

  &__body {
    @include mobile {
      width: fit-content;
      padding: 0 2rem;
    }
  }

  &__overflow-container {
    @include mobile {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  &__foot {
    margin-top: 3rem;
    padding: 0 2rem;

    @include mobile {
      margin-top: 2rem;
    }

    [data-row-template] {
      display: none;
    }
  }

  &__row {
    display: flex;

    &:first-child {
      .table__cell {
        justify-content: center;
        border-top: 1px solid $gray-dark;
      }
    }

    .table__cell {
      display: flex;
      min-height: 4rem;
      align-items: center;
      padding: 0 2rem;
      border-bottom: 1px solid $gray-dark;

      @include mobile {
        min-width: 15rem;
      }

      &--action {
        flex: unset;
        width: 4rem;
        border: none !important;
        padding: 0;
      }

      &:first-child {
        flex: 2.75;
        border-left: 1px solid $gray-dark;
        @include mobile {
          min-width: 23rem;
        }
      }

      input[type='number'],
      input[type='text'] {
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center;
        font-family: $font;
        width: 100%;
        height: 4rem;
      }

      input[type='text'] {
        font-weight: 700;
        text-align: left;
        &::placeholder {
          font-weight: 400;
        }
      }
    }
  }

  &__cell {
    flex: 1;
    &:not(:last-child) {
      border-right: 1px solid $gray-dark;
    }

    p {
      font-size: 1.5rem;
      font-weight: bold;
      line-height: calc(23 / 15);
    }

    .form__checkbox {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__remove-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    font-size: 0;

    svg {
      width: 1.5rem;
      height: 0.2rem;
      pointer-events: none;
    }
  }
}
