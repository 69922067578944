.form-radio {
  display: inline-block;
  position: relative;
  padding-left: 2.8rem;
  user-select: none;
  font-size: 1.7rem;
  line-height: calc(25 / 17);
  color: $black;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .form-radio__checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.2em;
    left: 0;
    height: 1.8rem;
    width: 1.8rem;
    border-radius: 50%;
    border: solid 2px $black;

    &:after {
      content: '';
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      background-color: $black;
      opacity: 0;
      transition: opacity $trans;
      border-radius: 50%;
    }
  }

  input:checked ~ .form-radio__checkmark:after {
    opacity: 1;
  }

  @include desktop {
    &:hover {
      .form-radio__checkmark:after {
        opacity: 1;
      }
    }
  }
}
