.breadcrumbs {
  display: flex;
  align-items: center;
  width: 73.5rem;
  font-size: 1.7rem;
  font-weight: 400;
  color: $black;
  white-space: nowrap;

  @include mobile {
    display: inline;
    width: 100%;
    font-size: 1.3rem;
    white-space: unset;
    line-height: 1.8;
  }

  & > * {
    display: inline-block;
    font-size: inherit;
    font-weight: inherit;
    color: rgba($black, 0.5);
    @include mobile {
      display: inline;
    }
  }

  &__separator {
    display: inline-block;
    width: 1.7rem;
    height: 1.7rem;
    margin: 0 1rem;
    flex-shrink: 0;

    @include mobile {
      margin: 0 0.5rem;
      position: relative;
    }

    svg {
      width: 100%;
      height: 100%;

      @include mobile {
        position: absolute;
        z-index: 2;
        top: 0.4rem;
      }
    }
  }

  a {
    transition: color $trans;
    user-select: none;

    &:hover {
      color: $black;
    }
  }

  .active {
    color: $black !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
