.newsletter {
  display: flex;
  padding: 4rem 8rem;
  align-items: center;

  @include mobile {
    flex-direction: column;
    padding: 2rem 2rem 3rem 2rem;
  }

  &__content {
    width: 84rem;

    @include mobile {
      width: 100%;
    }
  }
  &__cta {
    margin-left: auto;

    @include mobile {
      margin: 2rem auto 0 0;
    }
  }

  &--green-1 {
    background-color: $secondary-green-1;
  }

  &--green {
    background-color: $secondary-green;
  }

  &--gold {
    background-color: $secondary-gold;
  }

  &--blue {
    background-color: $secondary-blue;
  }

  &--red {
    background-color: $secondary-red;
  }
}
