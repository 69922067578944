.steps {
  display: flex;
  user-select: none;

  &__step {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      flex: 1;

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $gray-dark;
      }
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.6rem;
      height: 3.6rem;
      border: 2px solid $gray-dark;
      border-radius: 50%;
      background-color: $gray-dark;
      color: $white;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 1.2;
      flex-shrink: 0;
    }

    &.active {
      &:after {
        background-image: linear-gradient(
          to right,
          $secondary-blue 50%,
          $gray-dark 50%
        );

        @include mobile {
          background-image: none;
        }
      }

      span {
        background-color: $white;
        border-color: $secondary-blue;
        color: $secondary-blue;
      }
    }

    &.filled {
      &:after {
        background-color: $secondary-blue;
      }

      span {
        background-color: $secondary-blue;
        border-color: $secondary-blue;
      }
    }
  }
}
