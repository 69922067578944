.heading {
  font-weight: bold;
  color: $black;

  &--l {
    // font-size: 7.7rem;
    font-size: 6.2rem;
    line-height: calc(83 / 77);
    @include mobile {
      font-size: 4.3rem;
      line-height: calc(49 / 43);
    }
  }

  &--m {
    font-size: 5.7rem;
    line-height: calc(63 / 57);

    @include mobile {
      line-height: calc(41 / 35);
      font-size: 3.5rem;
    }
  }

  &--ml {
    font-size: 3.9rem;
    line-height: calc(45 / 39);
  }

  &--ms {
    font-size: 2.9rem;
    line-height: calc(37 / 29);
  }

  &--s {
    font-size: 2.1rem;
    line-height: calc(29 / 21);
  }
}
