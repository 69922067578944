$imgPath: '/img/';
$trans: 250ms ease-in-out;
$trans-long: 450ms ease-in-out;

$font: 'Helvetica Neue', sans-serif;

$white: #ffffff;
$black: #000000;
$gray: #f2f2f2;
$gray-dark: #d9d9d9;
$orangey: #FF2C00;
$light-orangey: #ffd5cc;
$pink: #FBC2A2;
$light-pink: #fde4d4;
$light-pink-2: #f79b82;

// $secondary-blue: #6c95fc;
// $secondary-blue: #0297a3;
$secondary-blue: #085b73; // nauja
$light-secondary-blue: #C7CED8; // nauja

// $secondary-red: #fd4740;
// $secondary-red: #de2682;
$secondary-red: #ed1d84; // nauja
$light-secondary-red: #f7afc9; // nauja

// $secondary-gold: #b4b075;
// $secondary-gold: #814057;
$secondary-gold: #71364b; // nauja
$light-secondary-gold: #b7999f; // nauja

// $secondary-purple: #9e7de5;
// $secondary-purple: #8946d6;
$secondary-purple: #422069; // nauja
$light-secondary-purple: #b3a6c3; // nauja

// $light-purple: #ede3f9;
$light-purple: #bd95ad; // nauja
$light-light-purple: #e0cfda; // nauja

// $secondary-green: #6acc86;
// $secondary-green: #6acc86;
$secondary-green: #70c48e; // nauja
$light-secondary-green: #C5E4CD; // nauja

// $secondary-green-1: #3fceb9;
// $secondary-green-1: #70c48e;
$secondary-green-1: #0297a3; // nauja
$light-secondary-green-1: #AACDD4; // nauja

// $secondary-yellow: #fec22d;
// $secondary-yellow: #d1cf00;
$secondary-yellow: #e7e514; // nauja
$light-secondary-yellow: #f3f3ae; // nauja

$shadow: 0 0 4rem #0000001a;
$border-radius: 0.5rem;
