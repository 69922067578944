.hero {
  position: relative;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      flex-direction: column;
    }
  }

  &__left {
    width: 89rem;

    @include mobile {
      width: 100%;
    }
  }

  &__right {
    width: 27rem;
    @include mobile {
      width: 100%;
      margin-top: 4rem;
    }
  }

  &__quote {
    @extend .text-block, .text-block--black;

    blockquote {
      padding-left: 0;
      padding-top: 5rem;
    }
  }

  &__cta {
    margin-top: 3rem;
  }

  &__circle {
    width: 58rem;
    height: 58rem;
    border-radius: 50%;
    background-color: $secondary-purple;
    position: absolute;
    top: 50%;
    left: 50vw;
    margin-top: -29rem;
    margin-left: -50rem;
    z-index: -1;

    @include mobile {
      width: 24rem;
      height: 24rem;
      top: 50vh;
      margin-top: -12rem;
      margin-left: -12rem;
    }

    &--center {
      margin-top: 0;
      margin-left: 0;
      top: 50vh;
      left: 50vw;
      transform: translate(-50%, -50%);
    }

    &--light-purple {
      background-color: $light-purple;
    }
  }
}
