.image {
  display: inline-block;
  font-size: 0;

  img {
    width: 100%;
    height: auto;
  }

  &__caption {
    font-size: 1.5rem;
    line-height: calc(23 / 15);
    font-weight: 400;
    color: rgba($black, 0.5);
    margin-top: 2rem;

    @include mobile {
      margin-top: 1.5rem;
    }
  }

  &--seal {
    max-width: 27rem;

    @include mobile {
      max-width: 20.6rem;
    }
  }

  &--m-overflow {
    @include mobile {
      width: calc(100% + 4rem);
      margin-left: -2rem;
    }

    .image__caption {
      @include mobile {
        width: 100%;
        padding: 0 2rem;
      }
    }
  }
}
