.details {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 27rem;
    height: 27rem;
    border-radius: 50%;
    background-color: $gray;
    padding-top: 7.7rem;
    text-align: center;

    @include mobile {
      width: 20.6rem;
      height: 20.6rem;
      padding-top: 6.4rem;
    }

    &:nth-of-type(even) {
      margin-left: 4rem;
    }
    &:nth-of-type(n + 3) {
      margin-top: 4rem;
    }

    &:not(:first-of-type) {
      @include mobile {
        margin: 2rem 0 0 0;
      }
    }
  }

  &__text-big {
    @extend .heading--l;
    @extend .heading;
  }

  &__text-small {
    font-size: 1.7rem;
    line-height: calc(25 / 17);
    font-weight: 400;
    position: relative;
    padding-left: 1.8rem;

    @include mobile {
      font-size: 1.5rem;
    }

    &::before {
      content: '';
      display: block;
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 50%;
      background-color: $black;
      position: absolute;
      left: 0;
      top: 0.5em;
    }
  }
}
