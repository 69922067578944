.card {
  &__image {
    // display: block;
    flex-shrink: 0;
    font-size: 0;
    img {
      width: 100%;
      height: auto;
    }
  }

  &__content {
    padding-top: 2rem;
  }

  &__description {
    margin-top: 1.5rem;
  }

  &__title {
    @include mobile {
      font-size: 1.9rem;
    }
    a {
      font-size: inherit;
      line-height: inherit;
      @extend .link, .link--hasUnderline;
      font-weight: bold;
      position: relative;

      &::before {
        content: '';
        @include sheet;
      }
    }

    &--redEffect {
      @include card-title-hover($secondary-red);
    }

    &--greenEffect {
      @include card-title-hover($secondary-green-1);
    }

    &--blueEffect {
      @include card-title-hover($secondary-blue);
    }

    &--goldEffect {
      @include card-title-hover($secondary-gold);
    }

    &--purpleEffect {
      @include card-title-hover($secondary-purple);
    }

    &--yellowEffect {
      @include card-title-hover($secondary-yellow);
    }
  }

  &__details {
    display: inline-block;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: $black;
    line-height: calc(23 / 15);
  }

  &--meetings {
    .card__image {
      height: 58rem;
      @include img-bg;

      @include mobile {
        height: auto;
      }
    }

    .card__title {
      font-size: 2.9rem;
      line-height: calc(29 / 21);

      @include mobile {
        font-size: 1.9rem;
        line-height: calc(25 / 19);
      }
    }
  }

  &--events {
    width: 100%;

    .card__title {
      font-size: 2.1rem;
      line-height: calc(29 / 21);

      @include mobile {
        font-size: 1.9rem;
        line-height: calc(25 / 19);
      }
    }
  }

  &--sticky-1 {
    width: 100%;

    .card__image {
      height: 58rem;
      @include img-bg;

      @include mobile {
        height: auto;
      }
    }

    .card__title {
      font-size: 2.9rem;
      line-height: calc(37 / 29);
      @include mobile {
        font-size: 1.9rem;
        line-height: calc(25 / 19);
      }
    }
  }

  &--sticky-2 {
    width: 100%;

    .card__image {
    }

    .card__title {
      font-size: 2.9rem;
      line-height: calc(37 / 29);

      @include mobile {
        font-size: 1.9rem;
        line-height: calc(25 / 19);
      }
    }
  }

  &--wdd {
    display: flex;

    @include mobile {
      flex-direction: column;
    }

    .card {
      &__image {
        width: 58rem;
        height: 58rem;
        @include img-bg;

        @include mobile {
          width: calc(100% + 4rem);
          margin-left: -2rem;
          height: auto;
        }
      }
      &__content {
        padding: 0 0 22rem 4rem;
        position: relative;

        @include mobile {
          padding: 3rem 0 16.5rem 0;
        }

        .expandable__state-icon {
          position: absolute;
          right: 0;
          bottom: 0;
          @include mobile {
            right: unset;
            left: 0;
            padding: 0;
          }
        }

        .staff__person {
          margin-top: 0;

          @include mobile {
            margin-bottom: 2rem;
          }
        }
      }
    }
  }

  &--pdf {
    .card__image {
      position: relative;

      &::after {
        content: '';
        @include sheet;
        background-color: rgba($black, 0.05);
        pointer-events: none;
      }
    }
  }
}
