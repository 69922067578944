.link {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: calc(2.3 / 1.5);
  user-select: none;
  position: relative;
  cursor: pointer;

  svg {
    height: 1.8rem;
    width: 1.8rem;
    fill: $black;
    stroke: $black;
  }

  &--l {
    font-size: 1.7rem;
    line-height: calc(2.5 / 1.7);
  }

  &--hasOpacity {
    opacity: 0.5;
    transition: opacity $trans;
    font-weight: 400;

    @include desktop {
      &:hover {
        opacity: 1;
      }
    }
  }

  &--hasUnderline {
    display: inline !important;
    line-height: inherit;
    // line-height: 1.5;
    background-image: linear-gradient(
      to bottom,
      currentColor 0,
      currentColor 100%
    );

    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size $trans;
    position: static;

    @include desktop {
      &:hover {
        background-size: 100% 2px;
      }
    }

    @include mobile {
      background-image: none;
    }
  }

  &--accessibility {
    svg {
      height: 1.8rem;
      width: 2.2rem;
      stroke: none;
    }
  }

  &--black {
    @include link-color($black);
  }

  &--blue {
    @include link-color($secondary-blue);
  }

  &--green {
    @include link-color($secondary-green-1);
  }

  &--green-1 {
    @include link-color($secondary-green);
  }

  &--gold {
    @include link-color($secondary-gold);
  }

  &--red {
    @include link-color($secondary-red);
  }

  &--purple {
    @include link-color($secondary-purple);
  }

  &--light-purple {
    @include link-color($light-purple);
  }

  &--light-pink-2 {
    @include link-color($light-pink-2);
  }

  &--white {
    @include link-color($white);
  }

  &--orangey {
    @include link-color($orangey);
  }

  &--pink {
    @include link-color($pink);
  }

  &--yellow {
    @include link-color($secondary-yellow);
  }
}
