.related-links {
  h2 + ul {
    @include mobile {
      margin-top: 2rem;
    }
  }

  &--smallerSpacing {
    ul {
      li:not(:last-of-type) {
        margin-bottom: 1.5rem;
      }
    }
  }
}
