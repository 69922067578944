.section {
  .form {
    .text-block {
      + .grid {
        margin-top: 2rem;
      }

      + .table,
      + .form__control {
        margin-top: 4.35rem;

        @include mobile {
          margin-top: 3rem;
        }
      }
    }

    .grid--hasGutter {
      .grid__cell + .grid__cell {
        @include mobile {
          margin-top: 3.8rem;
        }
      }
    }

    .pagination--type-2 {
      @include mobile {
        margin-top: 2rem;
      }
    }
  }
  .show-more {
    margin-top: 3rem;

    @include mobile {
      margin-top: 4rem;
    }
  }

  .row {
    + .row {
      margin-top: 8rem;

      @include mobile {
        margin-top: 4rem;
      }
    }
  }
  .heading {
    + .text-block {
      margin-top: 2rem;
    }
  }
  .heading--l {
    + .text-block {
      margin-top: 4rem;

      @include mobile {
        margin-top: 1.5rem;
      }
    }
  }

  .grid {
    &__cell--1-2 {
      .slider {
        @include mobile {
          width: calc(100% + 4rem);
          margin-left: -2rem;
        }
      }
    }

    + .grid {
      margin-top: 4rem;

      @include mobile {
        margin-top: 2rem;
      }
    }

    + .grid--masonry {
      margin-top: 7rem;
    }

    + .timeline,
    + .staff {
      margin-top: 8rem;
    }

    &--forTextPage {
      .heading--m {
        + .text-block {
          margin-top: 4rem;
          @include mobile {
            margin-top: 3rem;
          }
        }
      }

      .text-block {
        + .image,
        + .grid {
          margin-top: 6rem;
        }

        + .social {
          @include mobile {
            margin-top: 4rem;
          }
        }
      }

      .image {
        + .text-block,
        + .grid {
          margin-top: 6rem;
        }
      }

      .grid {
        + .image,
        + .text-block {
          margin-top: 6rem;
        }
      }

      .grid__cell--smaller {
        .social {
          margin-top: 4rem;
          padding-right: 4rem;
        }

        .button-holder {
          right: 4rem;
        }
      }

      .social {
        + .related-links {
          margin-top: 6rem;
          @include mobile {
            margin-top: 4rem;
          }
        }
      }
    }
  }

  .text-block {
    + .form {
      margin-top: 5rem;
    }

    + .text-block {
      margin-top: 4rem;

      @include mobile {
        margin-top: 3rem;
      }
    }

    + .details {
      margin-top: 4rem;

      @include mobile {
        margin-top: 2rem;
      }
    }

    + .related-links {
      margin-top: 6rem;

      @include mobile {
        margin-top: 4rem;
      }
    }

    + .button {
      margin-top: 4rem;

      @include mobile {
        margin-top: 2rem;
      }
    }
  }

  + .section {
    // margin-top: 8rem;
  }

  .button-holder {
    position: absolute;
    right: 0;
    bottom: 0;

    @include mobile {
      display: flex;
      flex-direction: column;
      position: static;
    }

    .button--primary {
      @include mobile {
        align-self: flex-end;
      }
    }
  }

  &--hLineTop {
    margin-top: 8rem;
    position: relative;

    @include mobile {
      margin-top: 4rem;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      max-width: 120rem;
      left: 50%;
      top: -8rem;
      transform: translateX(-50%);
      position: absolute;
      background-color: $gray;

      @include mobile {
        max-width: calc(100% - 4rem);
        top: -4rem;
      }
    }
  }

  &--login {
    margin-top: 18rem;
  }

  &--first {
    margin-top: 18rem;
    padding-bottom: 12rem;

    @include mobile {
      margin-top: 9rem;
      padding-bottom: 6rem;
    }

    .breadcrumbs {
      margin-bottom: 6rem;

      @include mobile {
        margin-bottom: 2rem;
      }

      + .grid,
      + .marquee {
        @include mobile {
          margin-top: 2rem;
        }
      }
    }

    .steps {
      margin-bottom: 4rem;

      @include mobile {
        margin-bottom: 2rem;
      }
    }
  }

  &--regular {
    padding-bottom: 12rem;

    @include mobile {
      padding-bottom: 6rem;
    }
  }

  &--pb-0 {
    padding-bottom: 0 !important;
    margin-bottom: -1px;
  }

  &--newsletter {
    padding-bottom: 6rem;
    @include mobile {
      padding-bottom: 4rem;
    }
  }

  &--404,
  &--hero,
  &--center {
    display: flex;
    align-items: center;
    padding: 18rem 0;
    min-height: 100vh;

    @include mobile {
      padding-top: 11rem;
      padding-bottom: 8rem;
    }
  }

  &--center {
    @include mobile {
      align-items: flex-start;
    }
  }

  &--hero {
    @include mobile {
      padding-top: 11rem;
      padding-bottom: 6rem;
    }
  }

  &--404 {
    @include mobile {
      padding: 9rem 0;
    }
  }

  &--hero {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      max-width: 120rem;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      position: absolute;
      background-color: $gray;

      @include mobile {
        width: calc(100% - 4rem);
      }
    }
  }
}
