@mixin size($w, $h: $w) {
  width: $w;
  height: $h;
}

@mixin selection {
  &::-webkit-selection {
    @content;
  }
  &::-moz-selection {
    @content;
  }
  &::selection {
    @content;
  }
}

@mixin image($file) {
  $url: '../img/#{$file}';
  background-image: url($url);
}

@mixin max($maxWidth) {
  @media only screen and (max-width: $maxWidth) {
    @content;
  }
}

@mixin min($maxWidth) {
  @media only screen and (min-width: $maxWidth) {
    @content;
  }
}

@mixin full {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@mixin sheet($position: absolute) {
  position: $position;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

@mixin background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin background-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin clear {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin abcenter {
  @include translate3d(-50%, -50%, 0);
  position: absolute;
  top: 50%;
  left: 50%;
}

@mixin inliner {
  @include clear;
  font-size: 0;
  white-space: nowrap;
}

@mixin fontSrc($name) {
  src: url('../fonts/#{$name}.eot');
  src: url('../fonts/#{$name}.eot?#iefix') format('embedded-opentype'),
    url('../fonts/#{$name}.woff2') format('woff2'),
    url('../fonts/#{$name}.woff') format('woff'),
    url('../fonts/#{$name}.ttf') format('truetype');
}

//MEDIA queries

@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

// Style helpers

@mixin tag-color($color) {
  background-color: rgba($color, 0.14);
  color: $color;
}

@mixin button-color($color, $textColor: $white) {
  &.button--primary {
    background-color: $color;
    color: $textColor;
    &::after {
      background-color: $color;
    }
  }

  &.button--secondary {
    color: $color;
    .button__text::after {
      background-color: $color;
    }
  }
}

@mixin map-button-color($color) {
  color: $black;
  border: 2px solid $color;

  &:not(.map__button--isFilled) {
    transition: background-color 150ms ease-in-out, color 150ms ease-in-out;
    .map__button-arrow {
      svg {
        transition: fill 150ms ease-in-out;
      }
    }
    @include desktop {
      &:hover {
        background-color: $color;
        z-index: 9;
        color: $white;

        .map__button-arrow {
          fill: $white;
        }
      }
    }
  }

  &.map__button--isFilled {
    background-color: $color;
    color: $white;

    @include desktop {
      transition: background-color 150ms ease-in-out, border 150ms ease-in-out;
      &:hover {
        background-color: darken($color, 5%);
        border-color: darken($color, 5%);
      }
    }

    svg {
      stroke: $white;
      fill: $white;
    }
  }

  &.popperActive {
    background-color: $color !important;
    border-color: $color !important;
    z-index: 999 !important;
    @include image('icons/ic_close_small-white.svg');
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: center;

    .map__button-text,
    .map__button-arrow {
      display: none;
    }
  }
}

@mixin post-nav-hover($color) {
  .post-nav__button:hover ~ .post-nav__text {
    & > * {
      @include desktop {
        color: $color;
      }
    }
  }

  .post-nav__text {
    p {
      a {
        color: $color;

        &::after {
          background-color: $color;
        }
      }
    }
  }
}

@mixin card-title-hover($color) {
  a {
    &:hover {
      color: $color;

      &::after {
        background-color: $color;
      }
    }

    @include IE11-link-polifill;
  }
}

@mixin form-focus-color($color) {
  .form {
    &__input,
    &__textarea,
    &__select {
      caret-color: $color;

      &:focus {
        border-color: $color;
      }
    }
  }

  .selectize-input.dropdown-active {
    border-color: $color !important;
  }

  .form__control.dropdownOpen {
    .form__select--arrow {
      svg {
        stroke: $color;
      }
    }
  }
}

@mixin link-color($color) {
  color: $color;

  &.link--hasUnderline {
    @include IE11-link-polifill;
  }
}

@mixin IE11-link-polifill {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    background-image: none;
    border-bottom: 2px solid transparent;
    transition: border $trans;

    &:hover {
      border-color: currentColor;
    }
  }
}

@mixin icon-hover($color) {
  &:hover {
    svg {
      fill: $color;
      stroke: $color;
    }
  }
}

@mixin slider-color($color) {
  .swiper-pagination-bullets {
    .swiper-pagination-bullet-active {
      background-color: $color;
    }
    .swiper-pagination-bullet {
      @include desktop {
        &:hover {
          background-color: $color;
        }
      }
    }
  }
}

@mixin img-bg {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @supports not (object-fit: cover) {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}
