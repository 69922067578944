.expandable {
  &__item {
    width: 100%;
    border-bottom: 1px solid $gray;
  }

  &__inner {
    display: flex;
    padding: 2.8rem 0;

    &--l {
      padding: 4rem 0;
    }
  }

  &__title {
    font-size: 1.7rem;
    font-weight: 400;
    line-height: calc(25 / 17);

    .tag {
      @include mobile {
        display: inline-block;
        margin-top: 1rem;
      }
    }
  }

  &__content-title {
    font-size: 2.9rem;
    font-weight: 400;
    line-height: calc(37 / 29);
    margin-bottom: 1.5rem;

    @include mobile {
      font-size: 2.1rem;
      margin-bottom: 2rem;
    }
  }

  &__state-icon {
    display: inline-flex;
    align-items: center;
    font-size: 0;
    padding-left: 4rem;
    margin-left: auto;
    // overflow: hidden;
    @include mobile {
      padding-left: 2rem;
    }

    .button__text {
      @include mobile {
        // display: none !important;
      }
    }
  }

  .text-block {
    + .expandable__content-title {
      margin-top: 3rem;
    }
  }

  .dropdown {
    width: 100%;

    &__label {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 2.8rem 0;
      user-select: none;

      @include mobile {
        padding: 2rem 0;
      }

      &:hover {
        .button__text {
          &::after {
            transform: translateX(100%);
          }
        }
      }
    }

    &__content {
      padding: 0 0 8rem 0;
      margin-top: 1rem;

      @include mobile {
        padding: 0 0 4rem 0;
      }

      .grid {
        &__cell {
          + .grid__cell {
            @include mobile {
              margin-top: 4rem;
            }
          }
        }
      }
    }
  }

  &--wdd {
    .expandable__item {
      background-color: $white;
      position: relative;
      z-index: 2;
      &:not(:last-of-type) {
        padding-bottom: 6rem;
        border-bottom: 1px solid $gray;

        @include mobile {
          padding-bottom: 4rem;
        }
      }

      &:not(:first-of-type) {
        padding-top: 6rem;
        @include mobile {
          padding-top: 4rem;
        }
      }

      &:first-of-type {
        padding-top: 2rem;
      }

      &:last-of-type {
        border: none;
      }
      .dropdown__content {
        overflow: hidden !important;
      }
    }

    .dropdown__content {
      padding: 0;
      margin-top: 6rem;
    }
  }

  &--hasBorderTop {
    .expandable {
      &__item {
        border: none;
        border-top: 1px solid $gray;
      }
    }
  }

  &--hasBorderBottom {
    border-bottom: 1px solid $gray;
  }

  &--borderBlack {
    .expandable {
      &__item {
        border-color: rgba($black, 0.15);
      }
    }
  }
}
