@import '~tippy.js/dist/tippy.css';

[data-tippy-root] {
  .tippy-box {
    background-color: transparent !important;
    border-radius: 0;
    padding: 0;
    overflow: visible;
  }

  .tippy-backdrop {
    background: transparent !important;
  }

  // &[x-placement='left'] {
  //   .tippy-tooltip {
  //     transform: translateX(0) !important;
  //   }
  // }
  .tippy-content {
    padding: 0;
  }

  .tippy-box[data-theme~='white'] {
    box-shadow: 0 0 4rem rgba($black, 0.1);
  }

  .tippy-box[data-theme~='white'][data-placement^='top']
    > .tippy-arrow::before {
    border-top-color: $white;
  }
  .tippy-box[data-theme~='white'][data-placement^='bottom']
    > .tippy-arrow::before {
    border-bottom-color: $white;
  }
  .tippy-box[data-theme~='white'][data-placement^='left']
    > .tippy-arrow::before {
    border-left-color: $white;
  }
  .tippy-box[data-theme~='white'][data-placement^='right']
    > .tippy-arrow::before {
    border-right-color: $white;
  }
}

.tooltip {
  &__icon {
    display: inline-block;

    cursor: pointer;
    user-select: none;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: $black;
    }
  }

  &--text {
    padding: 2rem;
    background-color: $white;
    border-radius: $border-radius;

    span {
      color: $black;
      font-size: 1.5rem;
      line-height: calc(23 / 15);
    }
  }
}
