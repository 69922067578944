.form {
  display: block;
  width: 100%;

  &__body {
  }

  &__footer {
    display: flex;
    width: 100%;
    margin-top: 4rem;
    align-items: center;

    @include mobile {
      margin-top: 2rem;
    }

    .button--primary {
      margin-left: auto;
    }
  }

  &__footer-note {
    font-size: 1.5rem;
    line-height: calc(23 / 15);
    align-self: center;
    padding-right: 4rem;
  }

  &__control {
    display: block;
    width: 100%;
    position: relative;

    + .form__control {
      margin-top: 4.8rem;

      @include mobile {
        margin-top: 4rem;
      }
    }

    .file-upload {
      margin-top: 1.5rem;
    }

    &--hasReset {
      .form__input {
        padding-right: 20rem;
      }

      .form__content-holder {
        max-width: 15rem;
        position: absolute;
        right: 1rem;
        top: 1.2rem;
      }
    }

    &--search {
      width: 100%;
      // max-width: 42.5rem;

      .form__input {
        padding-right: 4rem;
      }

      .form__content-holder {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 4rem;
      }

      .button--submit {
        height: 100%;
      }
    }

    &--hasTooltip {
      .form__input {
        padding-right: 4rem;
      }

      .tooltip {
        position: absolute;
        right: 1rem;
        top: 1.5rem;
      }
    }

    &.active,
    &.selected {
      .form__label {
        transform: translateY(calc(-100% -0.75em)) scale(0.85);

        @include mobile {
          transform: translateY(-1.3em) scale(0.85);
        }
      }
    }

    &.dropdownOpen {
      .form__select--arrow {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &.notValid {
      .form__input,
      .form__textarea,
      .form__select {
        border-color: $secondary-red;
      }

      .form-checkbox {
        &__checkmark {
          border-color: $secondary-red;
        }
      }

      .form__error-message {
        display: block;
      }

      .selectize-input.dropdown-active,
      .selectize-input {
        border-color: $secondary-red !important;
      }
    }
  }

  &__label {
    display: flex;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: calc(2.5 / 1.7);
    color: rgba($black, 0.5);
    position: absolute;

    transform: translateY(-50%);
    top: 2.3rem;

    transition: font-size $trans, transform $trans;
    transform-origin: left center;
    will-change: transform;
    pointer-events: none;

    @include mobile {
      font-size: 1.5rem;
      position: relative;
      top: 1.5rem;
      margin-bottom: -1em;
      transform: translateY(0);
    }

    &--l {
      font-size: 2.9rem;
      line-height: calc(37 / 29);
      top: 3.8rem;

      @include mobile {
        font-size: 2.1rem;
        top: 2rem;
      }
    }
  }

  &__input,
  &__textarea {
    width: 100%;
    height: 4.6rem;
    padding: 0.3rem 0 0 0;
    border-bottom: 2px solid $black;
    font-family: $font;
    font-size: 1.7rem;
    font-weight: 400;
    line-height: calc(25 / 17);
    color: $black;
    transition: border $trans;
    caret-color: $secondary-blue;
    font-family: $font;

    @include mobile {
      font-size: 1.5rem;
      height: 4.4rem;
    }

    &:focus {
      border-color: $secondary-blue;
    }

    &--l {
      font-size: 2.9rem;
      line-height: calc(37 / 29);
      height: 7.7rem;

      @include mobile {
        font-size: 2.1rem;
        height: 5.5rem;
      }
    }
  }

  &__select {
    option {
      font-family: $font;
      font-size: 1.7rem;
      color: $black;
    }

    &--arrow {
      position: absolute;
      top: 1.8rem;
      right: 1rem;
      z-index: 3;

      svg {
        width: 1.8rem;
        height: 0.9rem;
        stroke: $black;
      }
    }
  }

  &__datepicker {
    @extend .form__input;
    padding-right: 4rem;

    @include image('icons/ic_calendar.svg');
    background-position: calc(100% - 1rem) center;
    background-repeat: no-repeat;
    background-size: 1.8rem;
  }

  &__textarea {
    resize: none;
    padding: 1.2rem 0;
  }

  //----------Checkbox-----------
  &__checkbox {
    display: inline-block;
    position: relative;
    user-select: none;
    color: $black;
    height: 1.8rem;
    width: 1.8rem;
    border: solid 2px $black;
    transition: background $trans;
    background-position: center 0.1rem;
    background-repeat: no-repeat;
    background-size: 1rem;
    @include image('icons/ic_check.svg');

    &:checked {
      background-color: $black;
    }

    @include desktop {
      &:hover {
        background-color: $black;
      }
    }
  }

  &__error-message {
    display: none;
    width: 100%;
    margin-top: 0.3rem;
    font-size: 1.4rem;
    line-height: calc(24 / 14);
    font-weight: 400;
    color: $secondary-red;
  }

  // &__success {
  //   display: flex;
  //   justify-content: center;

  //   @include sheet;

  //   @include mobile {
  //     position: static;
  //   }

  //   .align-center {
  //     display: flex;
  //     flex-direction: column;
  //     flex: 1;
  //     height: 100%;
  //     justify-content: center;
  //   }
  // }

  // &__success-message {
  //   width: 41rem;
  //   text-align: left;
  // }

  // &__success-icon {
  //   display: block;
  //   margin-bottom: 4rem;
  //   margin-right: auto;

  //   @include image('icons/ic_guaranty.svg');
  //   @include background-cover;
  //   width: 6.2rem;
  //   height: 6.2rem;
  //   background-size: 6rem;
  // }

  // &__success-prices {
  //   display: flex;
  //   justify-content: space-between;
  //   margin-top: 4rem;

  //   + .text-block {
  //     margin-top: 4rem;
  //   }
  // }

  // &__success-pricer {
  //   width: 45%;
  //   padding-left: 2rem;
  //   border-left: 2px solid $yellow;

  //   .text-block {
  //     font-weight: 500%;
  //     color: $blue;
  //   }
  // }

  // &__succees-price {
  //   font-size: 3.6rem;
  //   font-weight: 500;
  //   margin-top: 1.5rem;
  //   line-height: 1;
  // }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 2px solid $black;
    -webkit-text-fill-color: $black;
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &--gold {
    @include form-focus-color($secondary-gold);
  }

  &--blue {
    @include form-focus-color($secondary-blue);
  }

  &--green {
    @include form-focus-color($secondary-green-1);
  }

  &--red {
    @include form-focus-color($secondary-red);
  }

  &--black {
    @include form-focus-color($black);
  }

  &--light-pink-2 {
    @include form-focus-color($light-pink-2);
  }
}
