.pdf {
  &--big {
    width: 46.5rem;
    height: 60rem;
  }

  &--m {
    width: 27rem;
    height: 38rem;
  }
}
