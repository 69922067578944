.header {
  // background-color: $white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  // background-color: $white;

  &__container {
    display: flex;
    align-items: flex-start;
    padding-top: 4rem;

    @include mobile {
      padding-top: 2rem;
    }
  }
  &__logo {
    position: relative;
    z-index: 5;
    svg {
      height: 6rem;
      width: 12.4rem;

      @include mobile {
        height: 5rem;
        width: 10.27rem;
      }
    }
  }

  &__menu {
    display: none;
  }

  &__nav {
    display: flex;
    width: 100%;
    margin-left: 11.5rem;

    @include mobile {
      flex-direction: column;
      position: fixed;
      top: 0;
      height: 100vh;
      width: 100%;
      z-index: 100;
      margin-left: 0;
      background-color: $black;

      pointer-events: none;
      visibility: hidden;
      left: 100%;
      transition: transform $trans, visibility $trans;
    }

    .list--1 {
      @include mobile {
        // display: block;
        overflow: auto;
      }
    }

    .list--2 {
      margin-left: auto;
      @include mobile {
        margin-left: 0;
        flex-direction: row;

        padding: 3.6rem 2rem;
        top: 0;
        left: 0;
      }

      .list__item {
        &:not(:last-of-type) {
          margin-right: 3rem;
        }
      }
    }

    .list--3 {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 3rem 2rem;
    }

    .list--1,
    .list--3 {
      .link--l {
        @include mobile {
          font-size: 2.7rem;
        }
      }
    }

    .link--black {
      @include mobile {
        color: $white;
      }
    }

    svg {
      @include mobile {
        fill: $white;
        stroke: $white;
      }
    }

    .link--hasUnderline {
      line-height: 1.2;
    }
  }
  &__nav-inner {
    display: flex;
    width: 100%;
    @include mobile {
      flex-direction: column;
      min-height: 100vh;
      padding: 11rem 2rem 10rem 2rem;
    }
  }
  &__menu {
  }

  &__dropdown {
    .dropdown__label {
      display: block;
      &::after {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        left: 0;
        height: 0.5rem;
        width: 100%;
        // z-index: -1;
      }
    }
    .dropdown__content {
      margin-top: 2px;
      width: 18rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      left: 0;

      &::before {
        content: '';
        display: block;
        width: 200vw;
        height: 100%;
        position: absolute;
        background-color: $white;
        top: 0;
        left: 0;
        transform: translate(-50%, 0);
        // box-shadow: 0 4rem 4rem 0 #0000001a;
        // pointer-events: none;

        @include mobile {
          display: none;
        }
      }
    }

    &--lang {
      .dropdown__content {
        width: 6rem;

        .list--lang {
          margin-left: -1.5rem;
        }
      }
    }
  }

  &__lang-dropdown {
    .dropdown__content {
      width: 6rem;
      // margin-top: 2.5rem;
      // box-shadow: $shadow;
      // left: 24%;
      // transform: translate3d(-50%, 0, 0);
      // border-radius: $border-radius;

      // &::before {
      //   content: '';
      //   display: block;
      //   width: 100%;
      //   height: 1.2rem;
      //   position: absolute;
      //   left: 0;
      //   top: 1px;
      //   transform: translate3d(0, -100%, 0);
      // }
    }

    .list--lang {
      margin: 1.2rem 0;
    }
  }

  .list--menu {
    & > li.active,
    .dropdown--hoverable:hover {
      .link--hasUnderline {
        background-size: 100% 2px;

        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          // background-image: none;
          // border-bottom: 2px solid transparent;
          // transition: border $trans;

          border-color: currentColor;
        }
      }
    }

    & > li.active {
      .link--hasUnderline {
        @include mobile {
          background-image: linear-gradient(currentColor, currentColor);
        }
      }
    }
  }

  .list--menu-inner {
    @include desktop {
      &::after {
        content: '';
        display: block;
        position: fixed;
        top: 12rem;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.05);
        z-index: -1;
        pointer-events: none;
      }
    }
  }

  .language {
    .list--menu-inner {
      .list__item {
        @include mobile {
          margin: 0;
        }
      }
      .link--black {
        @include mobile {
          padding: 1rem;
          color: $black;
          font-size: 1.7rem;
        }
      }
    }

    .dropdown__content {
      @include mobile {
        padding: 1rem 0;
        width: auto;
      }
    }

    .dropdown__label {
      @include desktop {
        line-height: 2rem;
      }
    }
  }

  .dropdown--fadeIn {
    .dropdown__content {
      @include mobile {
        position: absolute;
        top: 10rem;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        margin: 0;
        background-color: $black;
        z-index: 10;
        padding: 0 2rem;
        overflow: auto;
      }
    }

    .link--hasOpacity {
      @include mobile {
        font-size: 1.9rem;
        font-weight: 700;
        color: $white;
        opacity: 1;
      }
    }

    .list--menu-inner {
      padding: 2rem 0;
    }
  }

  .search,
  .accessibility {
    .link {
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 4rem;
        height: 100%;
        position: absolute;
        transform: translate(-50%, 0);
        left: 50%;
      }
    }
  }

  .desktop-hide {
    &.list--1 {
      padding: 1rem 0;
    }
  }

  &--light-purple {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-purple;
        }
      }
    }
  }
  &--purple {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-purple;
        }
      }
    }
  }

  &--black {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $black;
        }
      }
    }
  }
  &--blue {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-blue;
        }
      }
    }
  }
  &--green {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-green-1;
        }
      }
    }
  }
  &--green-1 {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-green;
        }
      }
    }
  }
  &--orangey {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $orangey;
        }
      }
    }
  }
  &--red {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-red;
        }
      }
    }
  }
  &--gold {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-gold;
        }
      }
    }
  }
  &--yellow {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $secondary-yellow;
        }
      }
    }
  }
  &--pink {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $pink;
        }
      }
    }
  }

  &--light-blue {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-blue;
        }
      }
    }
  }

  &--light-green-1 {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-green-1;
        }
      }
    }
  }

  &--light-green {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-green;
        }
      }
    }
  }

  &--light-orangey {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-orangey;
        }
      }
    }
  }

  &--light-red {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-red;
        }
      }
    }
  }

  &--light-gold {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-gold;
        }
      }
    }
  }

  &--light-yellow {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-yellow;
        }
      }
    }
  }

  &--light-purple {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-secondary-purple;
        }
      }
    }
  }

  &--light-light-purple {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-light-purple;
        }
      }
    }
  }

  &--light-pink {
    .header__dropdown {
      .dropdown__content {
        &::before {
          background-color: $light-pink;
        }
      }
    }
  }

  &--white {
    .list--1 {
      & > .list__item {
        &:hover {
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
            background-color: $white;
            z-index: -1;
          }
        }
      }
    }
  }
}

@include mobile {
  .header {
    &__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      margin-left: auto;
      z-index: 101;
      user-select: none;
      i {
        display: block;
        width: 100%;
        height: 2px;
        background-color: $black;
        pointer-events: none;
        transition: opacity 250ms ease-in-out, transform 250ms ease-in-out,
          background-color 600ms ease-in-out;
      }
      span {
        display: flex;
        align-items: center;
        position: relative;
        width: 4rem;
        height: 2.2rem;
        pointer-events: none;
        &:before,
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background-color: $black;
          position: absolute;
          left: 0;
          transition: transform 400ms ease-in-out, top 400ms ease-in-out,
            background-color 600ms ease-in-out;
        }
        &:before {
          top: 0;
        }
        &:after {
          bottom: 0;
        }
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 5rem;
        height: 5rem;
      }
    }
  }

  .menu-open {
    .header__logo {
      pointer-events: none;
      opacity: 0;
    }
    .header__nav {
      transform: translate(-100%, 0);
      pointer-events: auto;
      visibility: visible;

      .list--2 {
        position: fixed;
      }
    }
    .header__menu {
      span {
        &:before,
        &:after {
          transition: transform 400ms ease-in-out 220ms, top 200ms ease-in-out;
          background-color: $white;
        }
        &:before {
          top: 50%;
          transform: rotate(45deg);
        }
        &:after {
          top: 50%;
          bottom: unset;
          transform: rotate(-45deg);
        }
      }
      i {
        // transform: translateX(100%);
        opacity: 0;
        background-color: $white;
      }
    }

    .dropdown--fadeIn {
      .dropdown__content {
        @include mobile {
          position: fixed;
        }
      }
    }
  }
}
