.timeline {
  @include mobile {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__container {
    @include mobile {
      padding: 0 2rem;
      width: fit-content;
      display: flex;
    }
  }

  &__col {
    &:not(:first-child) {
      @include mobile {
        margin-left: 4rem;
      }
    }

    &:last-child {
      .timeline__item {
        &::after {
          @include mobile {
            display: none !important;
          }
        }
      }
    }
  }

  &__item {
    min-height: 27rem;

    @include mobile {
      max-width: 85vh;
      width: calc(100vw - 8rem);
      position: relative;

      min-height: 25rem;

      &:not(:first-of-type) {
        margin-top: 2rem;
      }

      &:first-child {
        padding-bottom: 2rem;
        &::after {
          content: '';
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          @include image('icons/ic_plus.svg');
          @include background-cover;
          position: absolute;
          bottom: 0;
          right: -2rem;
          transform: translate(50%, 0);
        }
      }
    }
  }

  &__image {
    font-size: 0;
    width: 27rem;
    height: 27rem;

    @include mobile {
      width: 23rem;
      height: 23rem;
    }

    @include img-bg;

    &--round {
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &__link {
    & > * {
      transition: color $trans;
    }

    &--green {
      &:hover {
        & > * {
          color: $secondary-green-1;
        }
      }
    }

    &--blue {
      &:hover {
        & > * {
          color: $secondary-blue;
        }
      }
    }

    &--gold {
      &:hover {
        & > * {
          color: $secondary-gold;
        }
      }
    }

    &--red {
      &:hover {
        & > * {
          color: $secondary-red;
        }
      }
    }
  }

  &__square {
    display: block;
    width: 27rem;
    height: 27rem;

    &--blue {
      background-color: $secondary-blue;
    }
  }

  &--awards {
    .timeline__item {
      min-height: 25rem;
      @include mobile {
        min-height: 18rem;
      }
    }
  }

  &--newsletter {
    @include mobile {
      width: 100%;
      margin: 0;
    }

    .timeline__item {
      min-height: 10rem;

      @include mobile {
        min-height: auto;
        max-width: unset;
        width: 100%;

        &:not(:last-of-type) {
          padding-bottom: 4rem;
        }

        &:not(:first-of-type) {
          margin-top: 2rem;
        }
      }
    }
  }
}

[data-show-more-content] .timeline--newsletter .grid__cell {
  @include mobile {
    margin-top: 2rem;
  }

  &:first-of-type {
    margin-top: 6rem;
  }
}

[data-show-more='1'] .timeline--newsletter .grid__cell {
  &:before {
    content: '';
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    @include image('icons/ic_plus.svg');
    @include background-cover;
    position: absolute;
    top: -2rem;
    left: 0;
    transform: translate(0, -100%);
  }
}

.timeline--newsletter.grid--hasItemSeparator .grid__cell {
  &::after {
    @include mobile {
      display: block;
      left: 0;
      bottom: 0;
      top: unset;
      right: unset;
      transform: translate(0, 0);
    }
  }

  &:last-of-type {
    &::after {
      display: none;
    }
  }
}
