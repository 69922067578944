.tag {
  display: inline-block;
  padding: 0 1rem;
  font-size: 1.5rem;
  line-height: 2.3rem;
  white-space: nowrap;
  border-radius: 4rem;
  font-weight: bold;
  user-select: none;

  &__container {
    .tag {
      margin: 0.5rem 1.5rem;
    }
  }

  &__inner {
    margin: -0.5rem -1.5rem;
  }

  &__container-label {
    margin-bottom: 2rem;
    p {
      font-size: 1.5rem;
      color: $black;
      font-weight: 700;
    }
  }

  &--blue {
    @include tag-color($secondary-blue);
  }

  &--green {
    @include tag-color($secondary-green-1);
  }

  &--green-1 {
    @include tag-color($secondary-green);
  }

  &--purple {
    @include tag-color($secondary-purple);
  }
  
  &--light-purple {
    @include tag-color($light-purple);
  }

  &--yellow {
    @include tag-color($secondary-yellow);
  }

  &--gold {
    @include tag-color($secondary-gold);
  }

  &--red {
    @include tag-color($secondary-red);
  }

  &--orangey {
    @include tag-color($orangey);
  }

  &--pink {
    @include tag-color($pink);
  }

  &--light-blue {
    @include tag-color($light-secondary-blue);
  }

  &--light-green-1 {
    @include tag-color($light-secondary-green-1);
  }

  &--light-green {
    @include tag-color($light-secondary-green);
  }

  &--light-orangey {
    @include tag-color($light-orangey);
  }

  &--light-red {
    @include tag-color($light-secondary-red);
  }

  &--light-gold {
    @include tag-color($light-secondary-gold);
  }

  &--light-yellow {
    @include tag-color($light-secondary-yellow);
  }

  &--light-purple {
    @include tag-color($light-secondary-purple);
  }

  &--light-light-purple {
    @include tag-color($light-light-purple);
  }

  &--light-pink {
    @include tag-color($light-pink);
  }

  &--black {
    @include tag-color($black);
    background-color: rgba($black, 0.05);
  }
}

button.tag--black,
a.tag--black {
  transition: background $trans;
  font-weight: 400;

  @include desktop {
    &:hover {
      background-color: rgba($black, 0.12);
    }
  }
}
