.side-menu {
  width: 100%;

  &__item {
    width: 100%;

    + .side-menu__item {
      margin-top: 1.5rem;
    }

    &.active {
      & > .link--hasOpacity {
        opacity: 1;
      }
    }
  }

  &--inner {
    margin-top: 1.5rem;

    .side-menu__item {
      position: relative;
      padding-left: 1.8rem;

      &::before {
        content: '';
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: $black;
        position: absolute;
        left: 0;
        top: 0.75rem;
      }
    }
  }
}
