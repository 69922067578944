.file-upload {
  &__remove {
    display: inline-block;
    width: 2.2rem;

    &:hover {
      &::after {
        background-color: rgba($black, 0.08);
      }
    }

    &:after {
      content: '';
      display: inline-block;
      @include image('icons/ic_x.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 1.2rem;

      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 3.2rem;
      z-index: 2;
      transition: background $trans;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    display: inline-flex;
    font-size: 1.7rem;
    font-weight: normal;
    color: $black;
    background-color: rgba($black, 0.05);
    display: inline-block;
    padding: 0.4rem 1rem;
    border-radius: 4rem;
    line-height: 1.2;
    cursor: pointer;
    transition: background $trans;
    overflow: hidden;
    position: relative;
  }

  input:focus + label,
  input + label:hover {
    background-color: rgba($black, 0.1);
  }
}
