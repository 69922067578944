.marquee {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 6rem;
  overflow: hidden;

  @include mobile {
    margin-bottom: 3rem;
  }

  &__inner {
    display: flex;
    position: relative;
    will-change: transform;
  }

  &__text {
    @extend .heading, .heading--l;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $black;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    padding: 0 1rem;
  }

  [data-direction='to-left'] {
    .marquee__text {
      left: -90%;
    }
  }
}
