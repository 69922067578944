.scroll-to {
  @extend .row;

  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);

  .button--secondary {
    svg {
      margin: 0 1rem 0 0;
    }
  }

  @include mobile {
    display: none;
  }
}

// @keyframes bounce-arrow {
//     0% {
//         transform: translateY(-0.5rem);
//     }
//     30% {
//         transform: translateY(0.2);
//     }

//     42% {
//         transform: translateY(0.5rem);
//     }

//     55% {
//         transform: translateY(0.2rem);
//     }
//     100% {
//         transform: translateY(-0.5rem);
//     }
// }
