p {
  margin: 0;
  font-size: 1.5rem;
  line-height: calc(23 / 15);
  font-weight: 400;

  @include mobile {
    line-height: calc(21 / 15);
  }
}

.large-text {
  margin: 0;
  font-size: 2.9rem;
  line-height: calc(37 / 29);
  font-weight: 400;

  @include mobile {
    font-size: 2.1rem;
    line-height: calc(27 / 21);
  }
}

p + p {
  //margin-top: 25px;
}

a {
  text-decoration: none;
  font-weight: bold;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: bold;
}

h1 {
  font-size: 7.7rem;
  line-height: calc(83 / 77);

  @include mobile {
    font-size: 4.3rem;
    line-height: calc(49 / 43);
  }
}

h2 {
  font-size: 5.7rem;
  line-height: calc(63 / 57);

  @include mobile {
    line-height: calc(41 / 35);
    font-size: 3.5rem;
  }
}

h3,
h4,
h5,
h6 {
  font-size: 2.1rem;
  line-height: calc(29 / 21);
}
