.note {
  display: flex;

  &__icon {
    flex-shrink: 0;
    width: 3.6rem;
    height: 3.6rem;
    margin-right: 1.5rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    align-self: center;
  }

  &--success {
  }

  &--error {
    .note__text {
      color: $secondary-red;
    }
  }

  + .form__body {
    margin-top: 3rem;
  }

  + .note {
    margin-top: 1.5rem;
  }
}
