.post-nav {
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }

  &__text {
    order: -1;
    // margin-right: 4rem;
    max-width: 73rem;

    @include mobile {
      width: 100%;
    }

    &--inRow {
      display: flex;
      max-width: none;

      @include mobile {
        flex-direction: column;
      }

      & > * {
        &:first-child {
          width: 42.5rem;

          @include mobile {
            width: 100%;
          }
        }

        &:nth-child(2) {
          width: 38rem;
          margin-left: 4rem;
          margin-top: 0;

          @include mobile {
            width: 100%;
            margin: 1.5rem 0 0 0;
          }
        }
      }
    }

    & > * {
      transition: color $trans-long;
    }

    a {
      user-select: none;

      // &:hover {
      //   color: $secondary-blue;
      // }
    }

    h3 {
      + p {
        margin-top: 2rem;
        @include mobile {
          margin-top: 1.5rem;
        }
      }
    }

    p {
      + .heading {
        margin-top: 1.4rem;
      }
    }

    p {
      a {
        @extend .link, .link--hasUnderline;
        font-size: 1.5rem;
        font-weight: 700;
      }
    }
  }

  &__button {
    margin-left: auto;
    @include mobile {
      margin: 2rem auto 0 0;
    }
  }

  &--align-center {
    align-items: center;
  }

  &--blue {
    @include post-nav-hover($secondary-blue);
  }

  &--gold {
    @include post-nav-hover($secondary-gold);
  }

  &--red {
    @include post-nav-hover($secondary-red);
  }

  &--green {
    @include post-nav-hover($secondary-green-1);
  }

  &--green-1 {
    @include post-nav-hover($secondary-green);
  }

  &--black {
    @include post-nav-hover($black);
  }

  &--purple {
    @include post-nav-hover($secondary-purple);
  }

  &--light-purple {
    @include post-nav-hover($light-purple);
  }

  &--orangey {
    @include post-nav-hover($orangey);
  }

  &--pink {
    @include post-nav-hover($pink);
  }

  &--yellow {
    @include post-nav-hover($secondary-yellow);
  }
}
