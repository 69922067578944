.show-more {
  display: flex;
  width: 100%;
  justify-content: center;

  svg [data-name='vertical'] {
    transition: transform $trans;
    transform-origin: center center;
  }
}

[data-show-more-content] {
  .grid__cell--1-4 {
    margin-top: 6rem;
    &:nth-of-type(4n + 1),
    &:nth-of-type(4n + 2),
    &:nth-of-type(4n + 3) {
      &::after {
        display: block;
      }
    }
  }
}

[data-show-more='0'] {
  [data-show-more-content] {
    display: none;
  }

  [data-show-more-button] {
    .button__text {
      &:first-of-type {
        display: none !important;
      }
    }
  }
}

[data-show-more='1'] {
  [data-show-more-button] {
    .button__text {
      &:last-of-type {
        display: none !important;
      }
    }
  }

  svg [data-name='vertical'] {
    transform: rotate(90deg);
  }
}
