.member {
  &__contact {
    @extend .text-block, .text-block--l, .text-block--dark;

    p {
      + ul {
        margin-top: 0;
      }
    }

    a {
      color: $secondary-gold;

      &::after {
        background-color: $secondary-gold;
      }
    }
  }

  &__contact-hidden {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 46.5rem;
    position: relative;

    p {
      position: absolute;
      width: 100%;
      max-width: 21rem;
      text-align: center;
    }
    img {
      width: 100%;
      height: auto;
    }
  }

  &__logo {
    margin-top: 3rem;
    font-size: 0;
    img {
      max-width: 35rem;
      max-height: 20rem;

      @include mobile {
        max-width: 100%;
      }
    }
  }

  .text-block {
    + .member__contact-hidden {
      margin-top: 3rem;
    }
  }
}
