.text-block {
  width: 100%;
  font-size: 1.5rem;
  color: $black;

  small {
    font-size: 0.68421em;
    line-height: 1.15;
  }

  a {
    // line-height: 1.533;
  }

  q {
    color: $black;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
  }

  .large-text {
    + p,
    + ul,
    + ol {
      margin-top: 4rem;

      @include mobile {
        margin-top: 2rem;
      }
    }

    + p {
      @include mobile {
        margin-top: 3rem;
      }
    }
  }

  p {
    // font-size: inherit;

    + p {
      margin-top: 1em;
    }

    + blockquote {
      margin-top: 4rem;
      @include mobile {
        margin-top: 3rem;
      }
    }

    + figure {
      margin-top: 4rem;
    }

    + table {
      margin-top: 1em;
    }

    + ul,
    + ol {
      margin-top: 3rem;

      @include mobile {
        margin-top: 2rem;
      }
    }
    + h1,
    + h2 {
      margin-top: 6rem;

      @include mobile {
        margin-top: 3rem;
      }
    }

    + h3,
    + h4,
    + h5,
    + h6 {
      // margin-top: 4rem;
      margin-top: 6rem;

      @include mobile {
        margin-top: 3rem;
      }
    }
  }

  blockquote {
    position: relative;
    padding-left: 4rem;
    color: $secondary-blue;

    &:before {
      content: '';
      display: block;
      width: 2rem;
      height: 2rem;
      @include image('icons/ic_quote--blue.svg');
      @include background-contain;
      position: absolute;
      left: 0;
      top: 0;
    }

    + p,
    + ul,
    + ol {
      margin-top: 4rem;

      @include mobile {
        margin-top: 3rem;
      }
    }
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 6rem;
      @include mobile {
        margin-top: 4rem;
      }
    }
  }

  figure {
    + p,
    + blockquote {
      margin-top: 4rem;
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 6rem;

      @include mobile {
        margin-top: 4rem;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  table {
    width: 120rem;
    background-color: $white;
    table-layout: fixed;

    @include mobile {
      display: block;
      width: calc(100% + 4rem);
      margin-left: -2rem;
      overflow: auto;
      margin-top: 8rem !important;
      &::before {
        content: attr(data-mobile-vertical-scroll-label);
        display: block;
        width: auto;
        padding-left: 3rem;
        line-height: 3rem;
        // color: $blue;
        height: 3rem;
        position: absolute;
        left: 50%;
        transform: translate3d(-50%, calc(-100% -2rem), 0);
        @include image('icons/ic_hand.svg');
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: left;
      }
    }

    tbody {
      @include mobile {
        display: block;
        width: fit-content;
        padding: 0 2rem;
      }
    }
    td {
      padding: 2rem;
      font-size: 1em;
    }

    th,
    td {
      @include mobile {
        min-width: 24rem;
      }
      &:not(:last-of-type) {
        border-right: 1px solid rgba($black, 0.15);
      }
    }

    th {
      padding: 2rem;
      color: $black;
      font-weight: 700;
      border-bottom: 2px solid $black;
      font-size: 1.134em;
    }

    tr {
      border: 1px solid rgba($black, 0.15);
    }

    + p,
    + h1,
    + h2,
    + h3,
    + h4 + h5 + h6,
    + ul,
    + ol,
    + img,
    .simpleParallax {
      margin-top: 3rem;
    }
  }

  strong {
    font-weight: 700;
  }

  h1,
  h2 {
    + p,
    + table {
      margin-top: 3rem;

      @include mobile {
        margin-top: 2rem;
      }
    }

    + ul,
    + ol,
    + blockquote {
      margin-top: 4rem;

      @include mobile {
        margin-top: 3rem;
      }
    }
  }

  h3,
  h4,
  h5,
  h6 {
    + p,
    + table {
      margin-top: 2rem;
    }

    + ul,
    + ol {
      margin-top: 3rem;
    }

    + blockquote {
      margin-top: 4rem;

      @include mobile {
        margin-top: 3rem;
      }
    }
  }

  ol,
  ul {
    li {
      &:not(:last-of-type) {
        margin-bottom: 2rem;
        @include mobile {
          margin-bottom: 1rem;
        }
      }
    }
  }

  ol {
    counter-reset: list;
    padding-left: 0;
    li {
      list-style: none;
      padding-left: 2.5rem;
      position: relative;
      &:before {
        counter-increment: list;

        content: counter(list) '.';
        display: inline-block;
        font-weight: bold;
        left: 0;
        top: 0;
        color: $black;
        position: absolute;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 1.8rem;

      &:before {
        content: '';
        display: block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        left: 0;
        top: 1.2rem;
        transform: translateY(-50%);
        background-color: $black;
        position: absolute;
      }

      &:not(:last-of-type) {
        @include mobile {
          margin-bottom: 1rem;
        }
      }
    }

    + p,
    table {
      margin-top: 4rem;
      @include mobile {
        margin-top: 3rem;
      }
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 6rem;

      @include mobile {
        margin-top: 4rem;
      }
    }
  }

  ol {
    + p,
    + table {
      margin-top: 3rem;

      @include mobile {
        margin-top: 2rem;
      }
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 6rem;

      @include mobile {
        margin-top: 4rem;
      }
    }
  }

  // q {
  //     font-weight: 500;
  // }

  // + .button {
  //     margin-top: 3rem;
  // }

  &--l {
    font-size: 2.9rem;

    @include mobile {
      font-size: 2.1rem;
      line-height: calc(27 / 21);
    }

    p {
      font-size: 2.9rem;
      line-height: calc(37 / 29);
      font-weight: 400;

      @include mobile {
        font-size: 2.1rem;
        line-height: calc(27 / 21);
      }
    }

    a {
      font-weight: normal;
    }

    ul {
      li {
        &:before {
          top: 2.4rem;
        }

        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }
    }
  }

  &--2cols {
    column-count: 2;
    column-gap: 4rem;

    @include mobile {
      column-count: unset;
      column-gap: unset;
    }
  }

  &--dark,
  &--blue {
    a {
      @extend .link--blue, .link--hasUnderline;
    }

    blockquote {
      color: $secondary-blue;

      &:before {
        @include image('icons/ic_quote--blue.svg');
      }
    }
  }

  &--green {
    a {
      @extend .link--green, .link--hasUnderline;
    }

    blockquote {
      color: $secondary-green-1;

      &:before {
        @include image('icons/ic_quote--green.svg');
      }
    }
  }

  &--red {
    a {
      @extend .link--red, .link--hasUnderline;
    }

    blockquote {
      color: $secondary-red;

      &:before {
        @include image('icons/ic_quote--red.svg');
      }
    }
  }

  &--gold {
    a {
      @extend .link--gold, .link--hasUnderline;
    }

    blockquote {
      color: $secondary-gold;

      &:before {
        @include image('icons/ic_quote--gold.svg');
      }
    }
  }

  &--light-pink-2 {
    a {
      @extend .link--light-pink-2, .link--hasUnderline;
    }

    blockquote {
      color: $light-pink-2;

      &:before {
        @include image('icons/ic_quote--pink-2.svg');
      }
    }
  }

  &--black {
    a {
      @extend .link--black, .link--hasUnderline;
    }

    blockquote {
      color: $black;

      &:before {
        @include image('icons/ic_quote--black.svg');
      }
    }
  }

  &--purple {
    a {
      @extend .link--purple, .link--hasUnderline;
    }

    blockquote {
      color: $secondary-purple;

      &:before {
        @include image('icons/ic_quote--purple.svg');
      }
    }
  }

  &--white {
    a {
      @extend .link--white, .link--hasOpacity;
      font-weight: bold;
    }

    blockquote {
      color: $white;

      &:before {
        @include image('icons/ic_quote--purple.svg');
      }
    }

    ul {
      li {
        &::before {
          background-color: $white;
        }
      }
    }
  }

  &--green-1 {
    a {
      @extend .link--green-1, .link--hasUnderline;
    }
  }

  &--light-purple {
    a {
      @extend .link--light-purple, .link--hasUnderline;
    }
  }

  &--orangey {
    a {
      @extend .link--orangey, .link--hasUnderline;
    }
  }

  &--pink {
    a {
      @extend .link--pink, .link--hasUnderline;
    }
  }

  &--yellow {
    a {
      @extend .link--yellow, .link--hasUnderline;
    }
  }
}
