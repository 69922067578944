.staff {
  &__content {
  }

  &__image {
    margin-bottom: 2rem;
    width: 27rem;
    height: 27rem;
    flex-shrink: 0;
    @include img-bg;

    @include mobile {
      width: 100%;
      height: auto;
    }
  }

  &__details {
    @extend .text-block, .text-block--dark;
    font-size: 1.5rem;

    a {
      font-weight: 400;
      color: $black;
    }

    ul {
      li {
        + li {
          margin-top: 0.25em;
        }
      }

      + p {
        margin-top: 2rem;
      }
    }

    + .link {
      margin-top: 1.5rem;
    }
  }

  &__title {
    font-weight: 400;
    font-size: 2.8rem;
    line-height: calc(36 / 28);

    @include mobile {
      font-size: 2.1rem;
      line-height: calc(27 / 21);
    }
  }

  &__person {
    @extend .details__text-small;
    display: inline-block;
    font-size: 1.5rem;
    line-height: calc(23 / 15);
    margin-top: 2rem;

    + .staff__details {
      margin-top: 2rem;
    }
  }

  &--horizontalList {
    @include mobile {
      width: calc(100% + 4rem);
      margin-left: -2rem;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
    .grid {
      @include mobile {
        flex-wrap: nowrap;
      }
    }
    .staff {
      &__container {
        @include mobile {
          padding: 0 2rem;
          width: fit-content;
        }
      }

      &__content {
      }

      &__item {
        @include mobile {
          max-width: 85vh;
          width: calc(100vw - 6rem);
          &:not(:first-of-type) {
            margin: 0 0 0 2rem;
          }
        }
      }
    }
  }

  &--verticalList {
    .staff__item {
      display: flex;
      padding-bottom: 6rem;

      @include mobile {
        padding-bottom: 0;
      }

      &:not(:first-of-type) {
        padding-top: 6rem;
        border-top: 1px solid $gray;

        @include mobile {
          border: none;
          padding-top: 0;
          margin-top: 4rem;
        }
      }

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    .staff__image {
      margin-bottom: 0;
    }
  }
}
