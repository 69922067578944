.grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &__cell {
    width: 100%;
    flex-shrink: 0;
    position: relative;

    &--1-2 {
      width: 50%;

      @include mobile {
        width: 100%;
      }
    }

    &--1-3 {
      width: 33.33%;
      @include mobile {
        width: 100%;
      }
    }

    &--1-4 {
      width: 25%;

      @include mobile {
        width: 100%;
      }
    }

    &--2-3 {
      width: 66.66%;

      @include mobile {
        width: 100%;
      }
    }

    // &.isHidden {
    //   // opacity: 0;
    //   transform: translateY(2rem);
    // }

    // &.fadeIn {
    //   // transition: opacity 2000ms ease-in-out;
    //   transition: transform 2000ms ease-in-out;
    //   // opacity: 1;
    //   transform: translateY(0);
    // }
  }

  &--hasGutter {
    .grid__cell {
      &--1-2 {
        width: calc(50% -2rem);

        @include mobile {
          width: 100%;
        }

        &:not(:first-of-type) {
          @include mobile {
            // margin-top: 4rem;
            margin-top: 2rem;
          }
        }

        &:nth-of-type(n + 3) {
          margin-top: 6rem;

          @include mobile {
            margin-top: 2rem;
          }
        }

        &:nth-child(odd) {
          margin-right: auto;
          @include mobile {
            margin-right: unset;
          }
        }
      }

      &--1-3 {
        width: calc(33.33% -2.7rem);
        @include mobile {
          width: 100%;
        }

        &:not(:first-child):not(:nth-child(4n)) {
          margin-left: 4rem;
          @include mobile {
            margin-left: 0;
          }
        }

        &:nth-child(n + 4) {
          margin-top: 4rem;
        }
        &:not(:first-child) {
          @include mobile {
            margin: 4rem 0 0 0;
          }
        }
      }

      &--1-4 {
        width: calc(25% - 3rem);
        margin-left: 4rem;

        @include mobile {
          width: 100%;
          &:not(:first-of-type) {
            margin: 4rem 0 0 0;
          }
        }

        &:first-of-type,
        &:nth-of-type(4n + 1) {
          margin-left: 0;
        }

        &:nth-of-type(n + 5) {
          margin-top: 6rem;
        }
      }
    }
  }

  &--vGutter-8 {
    .grid__cell {
      &--1-2 {
        &:nth-of-type(n + 3) {
          margin-top: 8rem;
        }

        &:not(:first-of-type) {
          @include mobile {
            margin-top: 4rem;
          }
        }
      }

      &--1-3 {
        &:nth-child(n + 4) {
          margin-top: 8rem;
        }
      }

      &--1-4 {
        &:nth-of-type(n + 5) {
          margin-top: 8rem;
        }
      }
    }
  }

  &--hasItemSeparator {
    .grid__cell {
      position: relative;

      &::after {
        content: '';
        display: none;
        width: 1.8rem;
        height: 1.8rem;
        @include image('icons/ic_plus.svg');
        @include background-cover;
        position: absolute;
        top: -2rem;
        right: -1.1rem;
        transform: translate(100%, -100%);
      }

      &--1-2 {
        &:nth-of-type(n + 3) {
          margin-top: 7rem;
        }

        &:nth-of-type(2n + 3) {
          &::after {
            display: block;

            @include mobile {
              display: none;
            }
          }
        }
        &:not(:first-child) {
          @include mobile {
            margin-top: 4rem;
          }
        }
      }

      &--1-4 {
        &:nth-of-type(n + 5) {
          margin-top: 6rem;
        }

        &:nth-of-type(4n + 5),
        &:nth-of-type(4n + 6),
        &:nth-of-type(4n + 7) {
          &::after {
            display: block;
          }
        }
      }
    }
  }

  &--forTextPage {
    flex-wrap: nowrap;
    justify-content: space-between;
    @include mobile {
      flex-wrap: wrap;
    }

    .grid__cell--larger {
      width: 73.5rem;
      @include mobile {
        width: 100%;
      }
    }

    .grid__cell--smaller {
      width: 46.5rem;
      margin-right: -4rem;
      @include mobile {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  &--masonry {
    margin-bottom: -8rem;
    @include mobile {
      margin-bottom: -4rem;
    }

    .grid__cell {
      display: flex;
      justify-content: center;
      &--1-2 {
        width: 58rem;
        margin-bottom: 3rem;

        @include mobile {
          width: 100%;
          margin-bottom: 4rem;
        }
      }
      &--1-4 {
        width: 27rem;
        margin-bottom: 8rem;
        @include mobile {
          width: 100%;
          margin-bottom: 4rem;
        }
      }
    }
  }

  &--hasSideMenu {
    justify-content: space-between;

    .grid__cell {
      &--menu {
        width: calc(100% - 94rem);
      }
      &--content {
        width: 90rem;
      }
    }
  }

  &.isHidden {
    position: relative;
    & > * {
      opacity: 0;
    }

    &::before {
      content: '';
      border: 1rem solid $gray;
      border-top: 1rem solid $secondary-blue;
      border-radius: 50%;
      width: 8rem;
      height: 8rem;
      animation: spin-loader 2s linear infinite;
      margin-left: auto;
      margin-right: auto;

      z-index: 10;
    }

    @keyframes spin-loader {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
