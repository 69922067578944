@import '~selectize/dist/css/selectize.css';
@import '~selectize/dist/css/selectize.default.css';

.selectize-control .selectize-input,
.selectize-dropdown {
  border: none;
  border-radius: 0;
  box-shadow: none;
  background-color: $white;
}

.selectize-control.single .selectize-input {
  box-shadow: none !important;
  background-image: none !important;
}

.selectize-input {
  border-bottom: 2px solid $black !important;
  background-color: transparent !important;
  padding: 1rem 2rem 1rem 0;
}

.selectize-input:after {
  display: none !important;
}

.selectize-dropdown,
.selectize-input,
.selectize-input input {
  color: $black;
  font-family: $font;
  font-size: 1.7rem;
  line-height: calc(25 / 17);
  font-weight: 400;
}

.selectize-input.dropdown-active::before {
  display: none;
}

.selectize-dropdown {
  margin-top: 1px;
  box-shadow: $shadow !important;
}

.selectize-control .selectize-input {
  background-image: none;
}

.selectize-dropdown-content {
  padding-bottom: 2rem;
}

.selectize-dropdown {
  border-radius: 0px 0px 0.5rem 0.5rem;
  overflow: hidden;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 4rem;
    background: transparent linear-gradient(180deg, #ffffff00 0%, #ffffff 100%)
      0% 0% no-repeat padding-box;
    position: absolute;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
  }
}

.selectize-dropdown .option {
  padding: 1rem 2rem;
  color: rgba($black, 0.5);
  cursor: pointer;
  // transition: color $trans, background $trans;

  &.selected {
    color: $black;
  }

  &.active {
    color: $black;
    background-color: rgba($black, 0.02);
  }
}

.selectize-control.multi {
  .selectize-input.has-items {
    padding: 0.8rem 4rem 0.2rem 0;
  }

  .selectize-input {
    min-height: 4.7rem;
    input {
      display: none !important;
    }
  }

  .selectize-input [data-value] {
    margin: 0;
    background-color: rgba($black, 0.05);
    border-radius: 4rem;
    padding: 0 1rem;
    font-size: 1.7rem;
    line-height: 2.8rem;
    white-space: nowrap;
    font-weight: 400;
    background-image: none !important;
    border: none !important;
    box-shadow: none !important;
    color: $black !important;
    padding-right: 3.2rem !important;
    text-shadow: none !important;
    overflow: hidden;
    flex-shrink: 0;

    + div {
      margin-left: 0.5rem;
    }

    .remove {
      width: 3.2rem;
      font-size: 2.3rem;
      font-weight: 300;
      line-height: 2.3rem;
      padding: 0 !important;
      text-align: center;
      border-left: none;
      transition: background $trans;
    }

    &.active {
      background-color: rgba($black, 0.08);
    }
  }

  .selectize-dropdown .option {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: inline-block;
      border: 2px solid $black;
      width: 1.8rem;
      height: 1.8rem;
      margin-right: 1rem;

      @include image('icons/ic_check.svg');
      background-position: center 0.1rem;
      background-repeat: no-repeat;
      background-size: 1rem;
    }

    &.selected {
      &::before {
        background-color: $black;
      }
    }
  }
}
