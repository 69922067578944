.accessibility {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  &__label,
  &__label p {
    font-size: 2.9rem;
    font-weight: 400;
    color: $black;
    line-height: calc(37 / 29);

    @include mobile {
      font-size: 2.1rem;
    }
  }

  &__options {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    @include mobile {
      margin: 2rem 0 0 0;
    }

    li {
      + li {
        margin-left: 2rem;
      }

      &.active {
        .accessibility__option {
          background-color: $black;
          svg {
            fill: $white;
          }
        }
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-size: 0;
    border: 2px solid $black;
    border-radius: 50%;
    flex-shrink: 0;
    width: 4.2rem;
    height: 4.2rem;
    transition: background $trans;

    svg {
      fill: $black;
      transition: fill $trans;
      pointer-events: none;
    }

    @include desktop {
      &:hover {
        background-color: $black;
        svg {
          fill: $white;
        }
      }
    }

    &--2 {
      width: 4.6rem;
      height: 4.6rem;
    }

    &--3 {
      width: 5rem;
      height: 5rem;
    }
  }
}

[data-accessibility='2'] {
  .text-block {
    &:not(.text-block--l) {
      p:not(.large-text) {
        font-size: 2rem;
      }
    }
  }
}

[data-accessibility='3'] {
  .text-block {
    &:not(.text-block--l) {
      p:not(.large-text) {
        font-size: 2.4rem;
      }
    }
  }
}
