@font-face {
  font-family: 'Helvetica Neue';
  @include fontSrc('helvetica/HelveticaNeue');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  @include fontSrc('helvetica/HelveticaNeue-Bold');
  font-weight: 700;
  font-style: normal;
}
