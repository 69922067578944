.button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-family: $font;
  color: $black;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: clac(25/17);
  flex-shrink: 0;
  // white-space: nowrap;
  @include mobile {
    font-size: 1.5rem;
    line-height: calc(21 / 15);
  }

  &--primary {
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
    width: 16rem;
    height: 16rem;
    border-radius: 50%;
    padding: 1rem;
    position: relative;
    line-height: 1.2;
    z-index: 2;

    @include mobile {
      width: 13.4rem;
      height: 13.4rem;
    }

    &:after {
      content: '';
      @include sheet;
      border-radius: 50%;
      z-index: -1;
      // transition: transform $trans;
      transition: $trans-long;
    }

    // @include mobile {
    //   padding: 0 3.2rem;
    // }

    @include desktop {
      &:hover {
        &::after {
          transform: scale(1.125);
        }
      }
    }

    &.button--large {
      width: 27rem;
      height: 27rem;
      padding: 2rem;

      @include mobile {
        width: 20.6rem;
        height: 20.6rem;
      }

      @include desktop {
        &:hover {
          &::after {
            transform: scale(1.072);
          }
        }
      }
    }
  }

  &--secondary {
    display: inline-flex;
    align-items: center;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      margin-left: 1rem;
      pointer-events: none;
    }

    @include desktop {
      &:hover {
        .button__text {
          background-size: 100% 2px;
        }
      }
    }

    .button__text {
      @extend .link, .link--hasUnderline;
      pointer-events: none;
      line-height: 1.5;
    }
  }

  &--submit {
    display: inline-flex;
    padding: 1rem;
    font-size: 0;
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }

    &.button--l {
      padding: 0.2rem;
      svg {
        width: 2.9rem;
        height: 2.9rem;

        @include mobile {
        }
      }
    }

    &:disabled {
      opacity: 0.3;
    }
  }

  &--blue {
    @include button-color($secondary-blue);
  }

  &--gold {
    @include button-color($secondary-gold);
  }

  &--red {
    @include button-color($secondary-red);
  }

  &--black {
    @include button-color($black);
  }

  &--green {
    @include button-color($secondary-green-1);
  }

  &--green-1 {
    @include button-color($secondary-green);
  }

  &--purple {
    @include button-color($secondary-purple);
  }

  &--light-purple {
    @include button-color($light-purple);
  }
  
  &--orangey {
    @include button-color($orangey);
  }

  &--pink {
    @include button-color($pink);
  }

  &--yellow {
    @include button-color($secondary-yellow);
  }

  &--light-yellow {
    @include button-color($light-secondary-blue);
  }

  &--light-green-1 {
    @include button-color($light-secondary-green-1);
  }

  &--light-green {
    @include button-color($light-secondary-green);
  }

  &--light-orangey {
    @include button-color($light-orangey);
  }

  &--light-red {
    @include button-color($light-secondary-red);
  }

  &--light-gold {
    @include button-color($light-secondary-gold);
  }

  &--light-yellow {
    @include button-color($light-secondary-yellow);
  }

  &--light-purple {
    @include button-color($light-secondary-purple);
  }

  &--light-light-purple {
    @include button-color($light-light-purple);
  }

  &--light-pink {
    @include button-color($light-pink);
  }

  &--light-pink-2 {
    @include button-color($light-pink-2);
  }

}
