.social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @include mobile {
    justify-content: start;
  }

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
    color: $black;
  }

  &__icon {
    margin-left: 3rem;

    @include mobile {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  &--afterText {
    justify-content: flex-start;
    margin-top: 6rem;
  }
}
