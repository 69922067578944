//----------Checkbox-----------
.form-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 2.8rem;
  user-select: none;
  font-size: 1.7rem;
  line-height: calc(25 / 17);
  color: $black;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .form-checkbox__checkmark {
    position: absolute;
    top: 0.2rem;
    left: 0;
    height: 1.8rem;
    width: 1.8rem;
    border: solid 2px $black;
    overflow: hidden;
    transition: background $trans;

    background-position: center 0.1rem;
    background-repeat: no-repeat;
    background-size: 1rem;
    @include image('icons/ic_check.svg');
  }

  input:checked ~ .form-checkbox__checkmark {
    background-color: $black;
  }

  @include desktop {
    &:hover {
      .form-checkbox__checkmark {
        background-color: $black;
      }
    }
  }
}
