.footer {
  padding-bottom: 4rem;
  position: relative;

  @include mobile {
    padding-bottom: 3rem;
  }

  &__city {
    @extend .details__text-small;
    font-size: 1.5rem;
    line-height: calc(23 / 15);
    margin-bottom: 2rem;

    @include mobile {
      line-height: calc(23 / 15);
    }
  }

  &__contacts {
    @extend .text-block, .text-block--black, .text-block--l;

    ul {
      li {
        padding-left: 0;

        &:before {
          display: none;
        }

        &:not(:last-of-type) {
          margin-bottom: 0;
        }
      }

      + p {
        margin-top: 2rem;
      }
    }
  }

  &__find-us {
    margin-top: 1.5rem;
  }

  &__foot {
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;

    @include mobile {
      margin-top: 4rem;
      flex-direction: column;
    }

    // .social {
    //   justify-content: flex-start;
    //   @include mobile {
    //     order: -1;
    //   }
    // }
  }

  &__disclaimer {
    @extend .text-block, .text-block--black;
    margin-right: auto;
    width: auto;

    @include mobile {
      margin-right: unset;
      width: 100%;
      margin-top: 4rem;
    }
  }

  &__policy {
    @include mobile {
      order: -1;
    }
  }

  &__designed {
    @extend .text-block;
    display: inline-flex;
    // align-items: center;
    margin-left: auto;
    width: auto;
    @include mobile {
      width: 100%;
      margin-left: 0;
      margin-top: 1.5rem;
    }

    svg {
      height: 1.4rem;
      width: auto;
      margin-left: 0.25em;
    }
  }

  .social {
    justify-content: flex-start;

    &__icon {
      &:first-child {
        margin-left: 0;
      }
    }
  }

  .heading--l {
    margin-bottom: 4rem;

    @include mobile {
      margin-bottom: 3rem;
    }
  }

  .row {
    &::before {
      content: '';
      display: block;
      width: 100%;
      border-top: 1px solid $gray;
      margin-bottom: 6rem;

      @include mobile {
        margin-bottom: 4rem;
      }
    }
  }

  &--border-black {
    .row {
      &::before {
        border-color: rgba($black, 0.15);
      }
    }
  }
}
