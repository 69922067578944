.row {
  margin: 0 auto;
  width: 100%;
  max-width: 128rem;
  padding: 0 4rem;

  @include mobile {
    padding: 0 2rem;
  }
}
