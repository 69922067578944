.map {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 95.7rem;
  position: relative;

  &__layer {
    pointer-events: none;
    @include mobile {
      &:not(.map__layer--mobile) {
        display: none;
      }
    }
    svg {
      width: 100%;
    }

    &--1 {
      @include sheet();
      z-index: 3;
    }

    &--2 {
      @include sheet();

      z-index: 2;
      pointer-events: all;
    }

    &--mobile {
      display: none;
      @include mobile {
        display: block;
        pointer-events: all;
      }

      svg {
        @include mobile {
          pointer-events: none;
          a {
            pointer-events: all;
          }
        }
      }
    }
  }

  &__overlay {
    display: block;
    @include sheet(fixed);
    background-color: rgba($black, 0.8);
    z-index: 99;
  }

  &__container {
    position: relative;
    @include mobile {
      min-width: 64rem;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 2rem;
    }
  }

  &__overflow {
    @include mobile {
      overflow-x: scroll;
      margin-left: -2rem;
      width: calc(100% + 4rem);
    }
  }

  &__button {
    @extend .button;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 2rem;
    text-align: center;
    font-size: 1.5rem;
    position: absolute;

    &--s {
      width: 18rem;
      height: 18rem;
    }

    &--m {
      width: 20rem;
      height: 20rem;
    }

    &--l {
      width: 25rem;
      height: 25rem;
    }

    &--xl {
      width: 32rem;
      height: 32rem;
    }

    &--pos1 {
      left: 48.5rem;
      top: 23.2rem;
    }

    &--pos2 {
      left: 30.5rem;
      top: 23.2rem;
    }
    &--pos3 {
      left: 39.5rem;
      top: 38.8rem;
    }

    &--pos4 {
      left: 4.5rem;
      top: 46.6rem;
    }

    &--pos5 {
      left: 15.3rem;
      top: 60rem;
    }
    &--pos6 {
      left: 60.5rem;
      top: 0;
    }
    &--pos7 {
      left: 65.5rem;
      top: 55rem;
    }

    &--blue {
      @include map-button-color($secondary-blue);
    }

    &--gold {
      @include map-button-color($secondary-gold);
    }

    &--red {
      @include map-button-color($secondary-red);
    }

    &--black {
      @include map-button-color($black);
    }

    &--green {
      @include map-button-color($secondary-green-1);
    }

    &--yellow {
      @include map-button-color($secondary-yellow);
    }

    &--purple {
      @include map-button-color($secondary-purple);
    }
  }

  &__button-arrow {
    margin-top: 1rem;
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
  &__button-text {
  }

  .popperActive {
    z-index: 100;
  }

  &__popper-content {
    text-align: left;
    width: 42rem;
    padding: 0 3rem 5rem 3rem;
    margin-top: -1rem;
    .heading {
      color: $white;
    }

    .text-block {
      height: 36rem;
      overflow: hidden;
      position: relative;
      margin-top: 2.4rem;
    }
  }

  &__hand {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: $black;
    svg {
      width: 2rem;
      height: 3rem;
      margin-right: 1rem;
    }
  }

  &__hand-inner {
    display: flex;
    align-items: center;
  }
}

// .ps-container > .ps-scrollbar-y-rail {
//   opacity: 0.6;
// }

.ps__rail-y {
  opacity: 1 !important;
}
