.filter {
  &__submit-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;

    @include mobile {
      margin-top: 2rem;
    }
  }
  &--inline {
    .filter {
      &__radio-list {
        display: flex;

        @include mobile {
          flex-direction: column;
        }

        li {
          &:not(:first-of-type) {
            margin-left: 3rem;

            @include mobile {
              margin: 2rem 0 0 0;
            }
          }
        }
      }
    }
  }

  &--hasShowMore {
    .dropdown {
      margin-top: 2px;

      @include mobile {
        margin-top: 2rem;
        position: relative;
      }
    }
    .dropdown__label {
      position: absolute;
      left: 46.5rem;
      top: -3.3rem;

      @include mobile {
        right: 0;
        top: unset;
        left: unset;
      }
    }

    .dropdown__content {
      padding-top: 4rem;

      @include mobile {
        margin-bottom: -4rem;
      }
    }

    .form__control--search {
      max-width: 42.5rem;
      @include mobile {
        max-width: unset;
      }
    }
  }
}
