.flex {
  display: flex;
}

.border-black {
  border-color: rgba($black, 0.15) !important;
}

.bg-light-purple {
  background-color: $light-purple;
  // background-color: #fbc2a2;
}

.desktop-hide {
  @include desktop {
    display: none !important;
  }
}

@include desktop {
  .mt-4 {
    margin-top: 4rem !important;
  }

  .mt-6 {
    margin-top: 6rem !important;
  }

  .mt-8 {
    margin-top: 8rem !important;
  }

  .mt-10 {
    margin-top: 10rem !important;
  }

  .mb-10 {
    margin-bottom: 10rem !important;
  }

  .text-right {
    text-align: right;
  }
}

@include mobile {
  .mt-2--mobile {
    margin-top: 2rem !important;
  }

  .mt-3--mobile {
    margin-top: 3rem !important;
  }

  .mt-4--mobile {
    margin-top: 4rem !important;
  }

  .mt-6--mobile {
    margin-top: 6rem !important;
  }

  .mb-3--mobile {
    margin-bottom: 3rem !important;
  }

  .mb-4--mobile {
    margin-bottom: 4rem !important;
  }

  .mb-6--mobile {
    margin-bottom: 6rem !important;
  }

  .mobile-hide {
    display: none !important;
  }
}
