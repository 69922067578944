.icon {
  display: inline-flex;
  font-size: 0;
  justify-content: center;
  align-items: center;
  position: relative;
  user-select: none;
  cursor: pointer;

  &::before {
    content: '';
    width: 3rem;
    height: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    width: 100%;
    height: 100%;
    fill: $black;
    stroke: $black;
    transition: fill $trans, stroke $trans;
  }

  &--facebook {
    width: 0.84rem;
    height: 1.8rem;
  }

  &--twiter {
    width: 1.971rem;
    height: 1.6rem;
  }

  &--linkedin,
  &--instagram {
    width: 1.8rem;
    height: 1.8rem;
  }

  &--green-hover {
    @include icon-hover($secondary-green-1);
  }

  &--blue-hover {
    @include icon-hover($secondary-blue);
  }

  &--gold-hover {
    @include icon-hover($secondary-gold);
  }

  &--red-hover {
    @include icon-hover($secondary-red);
  }

  &--purple-hover {
    @include icon-hover($secondary-purple);
  }
}
